<template>
  <div class="chart row-between column">
    <van-swipe
        vertical
        class="chartSwiper"
        :touchable="false"
        :autoplay="level == 0 ? 3000 : false"
        :show-indicators="false">
      <van-swipe-item v-if="level == 0"><div class="buleKey1">搜索关注公众号【助手AI】，防丢不迷路！</div></van-swipe-item>
      <van-swipe-item v-if="level == 0"><div class="buleKey1">请记住 https://www.wph3.cn 方便随时使用</div></van-swipe-item>
      <van-swipe-item v-if="level == 1"><div class="buleKey1">搜索关注公众号【助手AI】，防丢不迷路！</div></van-swipe-item>
      <van-swipe-item v-if="level == 1"><div class="buleKey1">请记住 https://www.wph3.cn 方便随时使用</div></van-swipe-item>
      <!--<van-swipe-item v-if="level == 0"><div class="buleKey1">提问次数：{{residualDegree}} 次</div></van-swipe-item>-->
    </van-swipe>

    <!--<div class="topBar" style="display: flex; align-items: center;padding:5px 10px;background-color: #fff;">
      <div class="topNotice" style="border-radius: 5px; flex: 1; text-align: left; ">
        <a class="buleKey1">收藏本站地址，防丢失不迷路</a>
      </div>
      <div class="shortcut" style="margin-left: auto; border-radius: 5px; padding: 3px 5px;background: #16a37f; color:#fff;" @click="goShortcut">生成快捷方式</div>
    </div>-->

    <div class="queryChatMain flex1" @scroll="orderScroll">
      <div
          v-for="(item, index) in queryChatList"
          class="queryChatListLi"
          :key="item.id">
        <div class="timeChart" v-if="index == 0 || (index + 1) % 5 == 1">{{item.createtime}}</div>
        <div class="row queryChatListLiLine end row-end" v-if="item?.userQuestion">
          <div class="queryChatListText buleKeyBack userQuestion">
            {{item.userQuestion}}
          </div>
          <div class="userHeadImg" />
        </div>
        <div class=" row row-start  queryChatListLiLine start" v-if="item?.answer">
          <div class="icon-Ai"/>
          <div class="queryChatListText answer align-center row" :class="item.statusAni" v-if="item.statusAni != 'noResidualDegree'" style="display: flex; flex-direction: column; align-items: flex-start;">
            <!--<span>{{item.answer}}</span>--><!--原版-->
            <span style="whiteSpace: pre-line;">{{item.answer}}</span><!--保留\n-->
            <!--<span v-html="item.answer"></span>--><!--<br>能使用-->
            <span :class="item.statusAni"></span>
            <button class="copy-btn" @click="copyAnswer(item.answer)" style="margin-top: 5px;">复制</button>
          </div>
          <div class="queryChatListText answer align-center row" :class="item.statusAni" v-else>
            <span v-if="item.isLogin" style="font-size: 0.2rem;">解锁无限提问次数，请<b @click="$router.push('/home')" class="colorRed pointerCursor">开通会员</b></span>
            <span v-if="!item.isLogin">点击右下角【我的】登录后继续使用</span>
            <span :class="item.statusAni"></span>
          </div>
        </div>
        <div class="examplesDiv" v-if="item?.examples">
          <div style="font-size:0.18rem;text-align: center;">使用示例<a @click="goShortcut" style="color:#16a37f;">【更多帮助】</a></div>
          <div>
            <!--<div style="font-size: 120%; text-align: center;">日常</div>-->
            <div class="example" @click="copySpanText"><span>{{item.examples[0][0]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[0][1]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[0][2]}}</span><span>→</span></div>
          </div>
          <div>
            <!--<div style="font-size: 120%; text-align: center;">工作</div>-->
            <div class="example" @click="copySpanText"><span>{{item.examples[1][0]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[1][1]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[1][2]}}</span><span>→</span></div>
          </div>
          <div>
            <!--<div style="font-size: 120%; text-align: center;">知识</div>-->
            <div class="example" @click="copySpanText"><span>{{item.examples[2][0]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[2][1]}}</span><span>→</span></div>
            <div class="example" @click="copySpanText"><span>{{item.examples[2][2]}}</span><span>→</span></div>
          </div>
          <!--<div style="text-align: center;background: #16a37f;border-radius:5px;padding:2%;"><a style="color: #fff;font-size:0.18rem" href="http://www.wph6.cn" target="_blank">点我立即体验助手AI在线起名</a></div>-->
        </div>
      </div>
      <!--      <van-pull-refresh v-model="loading" @refresh="onRefresh">-->
      <!--        -->
      <!--      </van-pull-refresh>-->
      <!--      <div class="topMember"  v-if="residualDegree == 0">-->
      <!--        <van-button type="primary" size="mini" @click="$router.push('/home')">今日次数已用完，可升级位为会员享受无限制服务</van-button>-->
      <!--      </div>-->
    </div>
    <div class="chartBottom row-between align-center">
      <van-cell-group inset class="flex1">
        <van-field
            v-model="message"
            rows="1"
            autosize
            label=""
            type="textarea"
            placeholder="您可以向助手AI提出任何问题"
            @focus="changeChartTop"
        >
        </van-field>
      </van-cell-group>
      <div class="applyButton align-center row-center" @click="changeChartPush">
        <div class="iconfont icon-fasong"></div>
      </div>

    </div>

  </div>
</template>

<script>
import { reactive, toRefs, defineComponent, onMounted, watchEffect, nextTick, computed } from 'vue'
import { queryChatRecord, sendMessage } from '@/api/chat'
import { useStore } from 'vuex'
import { showNotify } from 'vant'
import ClipboardJS from 'clipboard';//加入复制功能
import { EventSourcePolyfill } from 'event-source-polyfill';//加入SSE功能
import { getToken } from '@/utils/auth'//加入SSE功能
import { encode, decode } from 'urlencode'//加入SSE功能

const clipboard = new ClipboardJS('.copy-btn', {
  text(trigger) {
    const answer = trigger.parentNode.querySelector('span').innerHTML;
    //return answer.replace(/<br>/g, "\n");
    return answer;
  }
});
clipboard.on('success', () => {
  alert('复制成功');
});
clipboard.on('error', () => {
  alert('复制失败，请手动复制');
});

export default defineComponent({
  name: 'chart',
  setup () {
    const store = useStore()
    const state = reactive({
      message: '',
      queryChatList: [],
      queryChatListCard: [],
      residualDegree:  computed(() => store.getters.residualDegree),
      level: computed(() => store.getters.roles),
      isSendIng: false,//是否正在提问中
      pageNum: 1,
      // 下拉刷新
      count: 0,
      loading: false,
      isLastPage: false,
      bscroll: ''
    })
    const methods = reactive({
      goShortcut() {
        const ua = navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(ua)) {
          this.$router.push('/help');
        } else if (/android/.test(ua)) {
          this.$router.push('/help');
        } else {
          this.$router.push('/help');
        }
      },
      copySpanText(event) {
        var clickedElement = event.target;
        var divElement = clickedElement.closest('.example');
        var firstSpan = divElement.querySelector('span:first-child');
        var text = firstSpan.textContent;
        state.message = text;
      },
      //粘贴回答
      copyAnswer(answer) {
        // 点击按钮时不需要再创建 ClipboardJS 实例，直接触发事件即可
        const btn = document.querySelector('.copy-btn');
        //btn.click();
      },
      //解决方案1问题：每次点击都新建一个clipboardjs实例，但没有被销毁，所以每次点击复制都会触发多个success事件，导致弹出多个success框
      /*copyAnswer(answer) {
        const clipboard = new ClipboardJS('.copy-btn', {
          text() {
            return answer.replace(/<br>/g, "\n");;
          }
        });
        clipboard.on('success', () => {
          console.log('复制成功'+answer)
          alert('复制成功'+answer);
        });
        clipboard.on('error', () => {
          console.log('复制失败，请手动复制')
          alert('复制失败，请手动复制');
        });
      },*/
      // 查询历史数据
      loadingHistory (resolve) {
        const params = {
          pageSize: 10,
          pageNum: state.pageNum
        }
        queryChatRecord(params).then((res) => {
          return resolve(res)
        })
      },
      // 改变信息到最后一条
      changeChartTop (id) {
        const parent = document.querySelector('.queryChatMain')
        parent.scrollTo({
          top: parent.scrollHeight, // 需要父元素设置postion(relative、absolute、fixed)
          behavior: 'smooth'
        })
      },
      // 发送信息
      changeChartPush () {
        if (state.message != '' && !state.isSendIng) {
          state.isSendIng = true
          const notify = {
            'loading':'正在输入',
            'noResidualDegree': '您的提问次数已用完，想要继续体验，请开通会员'
          }
          const status = state.residualDegree != 0 ? 'loading' : 'noResidualDegree'
          const isLogin = store.getters.isLogin == 0 ? false : true;
          state.queryChatList.push({
            userQuestion: state.message,
            addId: `add${state.queryChatList.length}`,
            answer: notify[status],
            statusAni: status,
            isLogin: isLogin
          })
          const message = state.message
          state.message = ''
          setTimeout(() => {
            methods.changeChartTop()
          }, 5)
          //没有次数
          if (state.residualDegree != 0) {
            if (typeof (EventSource) !== 'undefined') {
              var msg = ''
              const evtSource = new EventSourcePolyfill('/api/chat/sendMessage?message='+encode(message, 'utf8'), {
                withCredentials: true,// 后端接口，要配置允许跨域属性
                headers: {
                  'access_token' : getToken(),
                  'content-type' : 'application/x-www-form-urlencoded'
                }
              })
              evtSource.addEventListener('message', function(e) {
                const dataObj = JSON.parse(e.data)
                var code = dataObj.code
                var id = dataObj.id
                var userId = dataObj.userId
                var userLevel = dataObj.userLevel
                var userQuestion = dataObj.userQuestion
                var answer = dataObj.answer
                var createtime = dataObj.createtime
                var updatetime = dataObj.updatetime
                var isLimit = dataObj.isLimit
                var deleted = dataObj.deleted
                if(code != 0){
                  msg = msg+answer
                  const myJson = {
                    id: id,
                    userId: userId,
                    userLevel: userLevel,
                    userQuestion: userQuestion,
                    answer: msg,
                    createtime: createtime,
                    updatetime: updatetime,
                    isLimit: isLimit,
                    deleted: deleted
                  };
                  state.queryChatList[state.queryChatList.length - 1] = myJson
                  setTimeout(() => {
                    methods.changeChartTop()
                  }, 5)
                  if (code != 6){
                    evtSource.close();
                    state.isSendIng = false
                    setTimeout(() => {
                      methods.changeChartTop()
                    }, 5)
                  }
                }else{
                  state.isSendIng = false
                  if (state.level == 0) {
                    store.dispatch('GetInfo').then(() => {})
                  }
                  setTimeout(() => {
                    methods.changeChartTop()
                  }, 5)
                  evtSource.close();//code是0就关闭，要不重复多次发请求
                }
              })
              // 与事件源的连接无法打开时触发
              evtSource.onerror = function(e){
                state.isSendIng = false
                state.queryChatList[state.queryChatList.length - 1].answer = '请稍后在试'
                setTimeout(() => {
                  methods.changeChartTop()
                }, 5)

                evtSource.close(); // 关闭连接
              }
            } else {
              console.log('当前浏览器不支持使用EventSource接收服务器推送事件!');
            }
          }
        } else {
          if(state.message == '') {
            return showNotify({ type: 'warning', message: '发送信息不能为空' })
          } else {
            return showNotify({ type: 'warning', message: '请等待前面的回答完成，同时不能有两个提问' })
          }

        }
      },
      // 插入数据到列表
      loadingData (isTop) {
        new Promise((resolve, reject) => {
          methods.loadingHistory(resolve)
        }).then((res) => {
          state.queryChatList.unshift(...res.data.list)
          state.loading = false
          // 是否为最后一页
          state.isLastPage = res.data.isLastPage
          if (isTop) {
            setTimeout(() => {
              methods.changeChartTop()
            }, 5)
          }
        })
      },
      // 下拉刷新
      onRefresh () {
        // 加载状态结束
        if (!state.isLastPage) {
          state.pageNum = state.pageNum + 1
          methods.loadingData()
        } else {
          state.loading = false
        }
      },
      orderScroll (e) {
        const conScrollTop = e.target.scrollTop//  内容滚动了的高度
        // 内容滚动了的高度 +  区域内容的高度 >= 可以滚动区域的高度
        // 则证明滑动到了页面底部，这个时候就去处理加载更多的逻辑
        // 加载状态结束
        if (!state.isLastPage && conScrollTop <= 0) {
          state.pageNum = state.pageNum + 1
          methods.loadingData()
        } else {
          state.loading = false
        }
      }

    })
    onMounted(() => {
      methods.loadingData(true)
      state.queryChatList.push({
        answer: '我是助手AI，我拥有广泛的知识库和强大的分析能力，可以帮助您解答各种问题，包括但不限于工作、学习、旅游、生活等方面。快来向我提问吧。',
        addId: `addhuanying`
      })
      state.queryChatList.push({
        examples: [["我要借朋友1000块钱，帮我写一个借款协议，要正式一点，不能让他不还", "帮我写一个表白文案，她喜欢吃小龙虾、喜欢追剧，真诚一点点", "番茄炒蛋怎么做，为什么我做的时候蛋总是一坨一坨的，不好吃"], ["写一个周报说明我作为销售经理这周工作很充实，完成了xxx", "写一篇关于防溺水教育的演讲稿，要求有例子、能引人深思", "tcp协议是怎么样的，三次握手的过程详细说一下"], ["究竟是先有鸡还是先有蛋，给我一个定论，不要模棱两可的", "社保包括什么，医保里的钱是怎么计算的","写一篇战狼2的观后感，刻苦铭心一点"]],
        addId: `addexamples`
      })
    })
    watchEffect(() => {
      nextTick(() => {
        state.bscroll && state.bscroll.refresh()
      })
    })
    return {
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
  //好像没起作用
  /*,
  unmounted() {
    console.log("unmounted()")
    clipboard.destroy(); // 组件销毁时销毁 ClipboardJS 实例
  }*/
})
</script>

<style scoped lang="less">
@import "./common";
.copy-btn {
  margin-left: 0px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

</style>
